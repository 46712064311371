<template>

  <v-row :class="'background'" align="center" justify="center" style="height:100%" no-gutters>
    <v-col style="max-width: 600px;">
      <v-card class="pb-6 px-3 ma-4 glass" >

        <v-row no-gutters>
         


          <v-col cols="12" class="pt-2 px-2">

            <v-form ref="login" lazy-validation>
              <div class="text-h5 mb-5 mt-10">
                Ingresa a tu cuenta
              </div>


              <v-text-field prepend-inner-icon="mdi-email" :rules="[rules.required]" :label="'Email'" filled rounded class="rounded-lg"
                v-model="username" @keyup.enter="doLogin()"></v-text-field>
              <v-text-field prepend-inner-icon="mdi-lock" :label="$i18n.t('LOGIN.passwordLabel')" class="rounded-lg"
                :rules="[rules.required]" v-model="password" filled rounded :type="showPassword ? 'password' : 'text'"
                @keyup.enter="doLogin()">
                <template v-slot:append>
                  <v-icon @click="togglePasswords">
                    {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                  </v-icon>
                </template>

              </v-text-field>
            </v-form>

            <v-alert v-if="error" type="error" border="left" colored-border icon="mdi-alert-circle-outline">
              Si el problema persiste, contacte al administrador.

              <v-btn rounded class="mx-auto mt-2 rounded-lg" color="green" dark large block
                href="https://wa.me/+59891272285?text=Hola!%20Ocurrio%20un%20problema%20al%20iniciar%20sesion%20en%20la%20app"
                target="_blank">
                <v-icon left color="white">
                  mdi-whatsapp
                </v-icon>
                Whatsapp
              </v-btn>
            </v-alert>
          </v-col>

          <v-col cols="12">
            <span class="ml-4" @click="showForgetPasswordModal = true"
              style="cursor: pointer; color: var(--v-primary-darken1);">{{ $i18n.t("LOGIN.forgotPassword") }}</span>
          </v-col>
        </v-row>

        <!-- 
        <v-row no-gutters justify="end">
          <v-col >
            <div class="pl-2">
            No tienes cuenta?
            <v-btn text color="#2196f3" @click="$router.replace('/signup')">
              Registrate
            </v-btn>
          </div>
          </v-col>
         
        </v-row> -->
        <v-row no-gutters justify="end">

          <v-col cols="6" class="pt-6">
            <v-btn class="rounded-lg" :loading="loadingButton" block x-large color="#2196f3" dark rounded depressed
              @click.prevent="doLogin">
              <v-icon left>
                mdi-login
              </v-icon>
              {{ $i18n.t("LOGIN.signIn") }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <!-- This is the password reset overlay -->
    <v-dialog v-model="showForgetPasswordModal" width="450px"
      :overlay-color="!$vuetify.theme.dark ? 'white' : '#303030'" overlay-opacity="0.7">
      <v-card class="password-reset__content">
        <v-card-title class="justify-center" style="color: #525252;">{{ $i18n.t("LOGIN.resetPassword") }}</v-card-title>
        <v-card-text class="pb-0 px-10">
          <v-subheader class="mb-4">
            Ingresa tu email para recibir un link de recuperación de contraseña.
          </v-subheader>
          <v-form ref="forgetPasswordForm" lazy-validation>
            <v-text-field outlined prepend-inner-icon="mdi-email" label="Email" v-model="forgetEmail"
              :rules="[rules.required]"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <div class="text-end" style="width: 100%;">
            <v-btn class="mr-2" @click="showForgetPasswordModal = false" text>{{ $i18n.t("GENERAL.close") }}</v-btn>
            <v-btn :loading="loadingForgetButton" depressed @click="forgetPasswordRequest" color="primary">{{
              $i18n.t("GENERAL.send") }}</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import firebase from "./../../firebase.js";

/* function whichAuth() {
  let auth
  if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(app, {
      persistence: indexedDBLocalPersistence
    })
  } else {
    auth = getAuth()
  }
  return auth
}
 */

//import firebase from 'firebase/compat/app';

import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Capacitor } from '@capacitor/core';
import { getAuth, initializeAuth, indexedDBLocalPersistence } from 'firebase/auth';
export default {

  mounted() {
    this.$vuetify.goTo(0)
    //this.startFirebaseUi();
  },

  data() {
    return {

      showPassword: true,
      username: "",
      password: "",
      showForgetPasswordModal: false,
      loadingButton: false,
      forgetEmail: "",
      forgetUsername: "",
      loadingForgetButton: false,
      rules: {
        required: (value) => !!value || "Este campo es requerido",
      },
      error: false
    }
  },

  methods: {
    togglePasswords() {
      this.showPassword = !this.showPassword;
    },
    startFirebaseUi() {
      var uiConfig = {
        signInSuccessUrl: '/',
        signInOptions: [
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
        tosUrl: '/',
        privacyPolicyUrl: '/'
      };
      var ui = new firebaseui.auth.AuthUI(firebase.auth());
      ui.start('#firebaseui-auth-container', uiConfig);

    },


    async forgetPasswordRequest() {
      if (this.$refs.forgetPasswordForm.validate()) {
        try {
          this.loadingForgetButton = true;

          const auth = firebase.auth

          let result = await sendPasswordResetEmail(auth, this.forgetEmail)

          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "success",
            title: "Email enviado",
            text: "Revisa tu bandeja de entrada",
          });
        } catch (error) {
          if (error.code == "auth/invalid-email") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Email invalido",
            });
          }

          if (error.code == "auth/user-not-found") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Usuario no encontrado",
            });
          }

          if (error.code == "auth/invalid-credential") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Credenciales invalidas",
            });
          }

          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "Error",
            text: "No se pudo enviar el email de recuperación",
          });
        }
        this.loadingForgetButton = false;
        this.showForgetPasswordModal = false;
      }
    },
    async doLogin() {
      if (this.$refs.login.validate()) {
        try {
          this.loadingButton = true;


          const auth = firebase.auth
          // Sign in
          const userCredential = await signInWithEmailAndPassword(auth, this.username.toLowerCase(), this.password);


          const user = userCredential.user;

          this.error = false;
          this.$vuetify.goTo(0);
        } catch (error) {
          let errorCode = error.code;

          this.error = true;
          if (errorCode == "auth/user-not-found") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Usuario no encontrado",
            });
          }

          if (errorCode == "auth/wrong-password") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Contraseña incorrecta",
            });
          }

          if (errorCode == "auth/invalid-email") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Email invalido",
            });
          }

          if (errorCode == "auth/user-disabled") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Usuario deshabilitado, contacte al administrador",
            });
          }

          if (errorCode == "auth/too-many-requests") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Demasiados intentos, intente mas tarde",
            });
          }

          if (errorCode == "auth/network-request-failed") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Error de red",
            });
          }

          if (errorCode == "auth/operation-not-allowed") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Operacion no permitida",
            });
          }

          if (errorCode == "auth/internal-error") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Error interno",
            });
          }

          if (errorCode == "auth/invalid-credential") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Credenciales invalidas",
            });
          }

          if (errorCode == "auth/invalid-verification-code") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Codigo de verificacion invalido",
            });
          }

          if (errorCode == "auth/invalid-verification-id") {
            this.$notify({
              group: "feedback",
              duration: 5000,
              type: "error",
              title: "",
              text: "Id de verificacion invalido",
            });
          }

        }
        this.loadingButton = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.background {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.dark-background {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #272727;
}

.card-content {
  box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border-top: 5px solid #d1404a;

  .card-content__footer {
    background-color: #d1404a !important;
    border-radius: 8px;
  }
}

.password-reset__content {
  box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  width: 450px;
}
</style>
